import type { AxiosResponse } from 'axios';
import type { PageInfoDto } from '@/types/contracts/generated/models/pageInfoDto';
import type { InfoDto } from '@/types/contracts/generated/models/infoDto';
import type { MessageForm } from '@/types/contracts/generated/models/messageForm';
import type { InfoFilterForm } from '@/types/contracts/generated/models/infoFilterForm';
import type { PersonDto } from '@/types/contracts/generated/models/personDto';
import type { InfoListDto } from '@/types/contracts/generated/models/infoListDto';
import { useResource } from '@/composables/http/use-resource';
import type { ExtendedNewsDto } from '@/types/contracts/generated/models/extendedNewsDto';

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/info`;

export function useCampusInfoApi() {
  const { makeGetRequest, makePutRequest, makePostRequest, makeDeleteRequest } = useResource();

  const fetchInfos = async (since?: string): Promise<InfoListDto> =>
    makeGetRequest(`${baseUrl}`, since ? { params: { since: since } } : undefined);

  const fetchSentInfos = async (since?: string): Promise<PageInfoDto> =>
    makeGetRequest(`${baseUrl}/send`, since ? { params: { since: since } } : undefined);

  const fetchFavInfos = async (since?: string): Promise<PageInfoDto> => makeGetRequest(`${baseUrl}/fav`);

  const sendInfo = async (message: MessageForm): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/send`, message);

  const markRead = async (info: InfoDto): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/read`, { fid: info.id, type: info.type });

  const markReadAll = async (filter: InfoFilterForm): Promise<AxiosResponse> =>
    makePostRequest(`${baseUrl}/readall`, filter);

  const toggleFav = async (info: InfoDto): Promise<AxiosResponse> => {
    if (info.fav) {
      return makePostRequest(`${baseUrl}/unfav`, { fid: info.id, type: info.type });
    } else {
      return makePostRequest(`${baseUrl}/fav`, { fid: info.id, type: info.type });
    }
  };

  const searchPerson = async (query: string): Promise<PersonDto[]> =>
    makeGetRequest(`${baseUrl}/buddy/search`, { params: { query: query } });

  const addBuddy = async (id: number): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/buddy/add/${id}`);
  const removeBuddy = async (id: number): Promise<AxiosResponse> => makeDeleteRequest(`${baseUrl}/buddy/remove/${id}`);
  const getBuddies = async (): Promise<PersonDto[]> => makeGetRequest(`${baseUrl}/buddy`);

  const getReplyInfo = async (id: number): Promise<InfoDto> => makeGetRequest(`${baseUrl}/reply/${id}`);

  const getExtendedNews = async (showAll?: boolean): Promise<ExtendedNewsDto[]> => makeGetRequest(`${baseUrl}/extendednews${showAll ? '?showAll=true' : ''}`);
  const putExtendedNews = async (updatedExtendedNews: FormData): Promise<AxiosResponse> => makePutRequest(`${baseUrl}/extendednews`, updatedExtendedNews);
  const putExtendedNewsReordered = async (updatedExtendedNews: any): Promise<AxiosResponse> => makePutRequest(`${baseUrl}/extendednews/reorder`, updatedExtendedNews);
  const postExtendedNews = async (newExtendedNews: FormData): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/extendednews`, newExtendedNews);
  const deleteExtendedNews = async (id: number): Promise<AxiosResponse> => makeDeleteRequest(`${baseUrl}/extendednews/${id}`);

  return {
    fetchInfos,
    fetchSentInfos,
    fetchFavInfos,
    sendInfo,
    markRead,
    markReadAll,
    toggleFav,
    getBuddies,
    searchPerson,
    addBuddy,
    removeBuddy,
    getReplyInfo,
    getExtendedNews,
    putExtendedNews,
    putExtendedNewsReordered,
    postExtendedNews,
    deleteExtendedNews
  };
}
